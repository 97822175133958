var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero"},[_c('div',{staticClass:"zero-head"},[_c('div',{staticClass:"head-box flex"},[_c('div',{staticClass:"head-box__logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/image/No1/Group-19.png"),"title":" EBC官网地址 https://www.ebcfin.com"}})])],1),_c('div',{staticClass:"head-box__tit flex-1"},[_c('router-link',{staticClass:"btns",attrs:{"to":"/"}},[_vm._v("www.ebcfin.com")])],1),_vm._m(0)])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"zero-video"},[_vm._m(1),_c('video',{staticClass:"zero-video__box",attrs:{"muted":"muted","autoplay":"autoplay","loop":"loop","id":"startvideo","playsinline":"true","webkit-playsinline":"true","poster":require('../assets/image/img/vedio-trading.gif')},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../assets/image/Home/video-1.mp4"),"type":"video/mp4"}}),_vm._m(2)])])]),_c('div',{staticClass:"content"},[_vm._m(3),_c('a',{staticClass:"zero-title",attrs:{"href":"https://trading.ebfin.com"},on:{"mouseover":function($event){return _vm.getName(2)},"mouseout":function($event){return _vm.getName(1)}}},[_c('img',{staticClass:"img",attrs:{"src":require(`../assets/image/img/over-${_vm.name}.png`)}})])]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-box__btn flex"},[_c('a',{staticClass:"left-btn",attrs:{"href":"https://client.ebccrm.com/signup?hl=zh"}},[_vm._v("免费开户")]),_c('a',{staticClass:"right-btn",attrs:{"href":"https://client.ebccrm.com/?hl=zh"}},[_vm._v("登录")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-video__title flex flex-ajcenter flex-column"},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/image/img/icon-2.svg")}}),_c('img',{staticClass:"img",attrs:{"src":require("../assets/image/img/icon-9.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('object',{attrs:{"width":"","height":"","type":"application/x-shockwave-flash","data":"myvideo.swf"}},[_c('param',{attrs:{"name":"movie","value":"myvideo.swf"}}),_c('param',{attrs:{"name":"flashvars","value":"autostart=true&file=myvideo.swf"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-desc"},[_vm._v(" 每一个"),_c('p',[_vm._v("梦想")]),_vm._v("都始于足下，每一步迈进都能成就"),_c('p',[_vm._v("不平凡")]),_c('br'),_c('br'),_vm._v("桥水、德邵、文艺复兴 …… "),_c('br'),_vm._v("他们都从平凡启航，或许最初并不知道自己能成为全球领先的对冲基金，"),_c('br'),_vm._v("但他们从一开始就知道自己心中的那个"),_c('p',[_vm._v("梦 ")]),_c('br'),_c('br'),_c('p',[_vm._v("From Zero to Hero")]),_vm._v(" 与你，一样"),_c('br'),_vm._v(" 每个交易员心中都有一个"),_c('p',[_vm._v("英雄梦")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-modular modular-one"},[_c('div',{staticClass:"content flex"},[_c('div',{staticClass:"modular-one__info"},[_c('div',{staticClass:"zero-modular__tit"},[_vm._v(" 优势一:轻松实现"),_c('p',[_vm._v("\"毫秒级\"")]),_vm._v("跟单 ")]),_c('div',{staticClass:"zero-modular__desc"},[_vm._v(" 基于MT底层通讯协议 ")]),_c('div',{staticClass:"zero-modular__desc1 flex"},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/image/img/icon-xx.svg")}}),_vm._v("免费 "),_c('img',{staticClass:"img",attrs:{"src":require("../assets/image/img/icon-xx.svg")}}),_vm._v("非ea插件跟单 "),_c('img',{staticClass:"img",attrs:{"src":require("../assets/image/img/icon-xx.svg")}}),_vm._v("跟单无需打开MT ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-modular modular-two"},[_c('div',{staticClass:"content flex"},[_c('div',{staticClass:"modular-two__info"},[_c('div',{staticClass:"zero-modular__tit"},[_vm._v(" 优势二:"),_c('p',[_vm._v("\"更专业\"")]),_vm._v("的信号选择 ")]),_c('div',{staticClass:"zero-modular__desc"},[_vm._v(" 首次引入基金评级系统。标准差、夏普比、净值余额比..."),_c('br'),_vm._v(" 专业评级指标将辅助你选择一个真正优质的信号。"),_c('br'),_c('div',{staticClass:"btns"},[_c('a',{attrs:{"href":"https://trading.ebfin.com","target":"_blank"}},[_vm._v(" 登录社区 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-modular modular-three"},[_c('div',{staticClass:"content flex"},[_c('div',{staticClass:"modular-three__info"},[_c('div',{staticClass:"zero-modular__tit"},[_vm._v(" 优势三:保护您的"),_c('p',[_vm._v("\"交易隐私\"")])]),_c('div',{staticClass:"zero-modular__desc"},[_vm._v(" 公私域信号分离，为交易隐私保驾护航；"),_c('br'),_vm._v(" 公域信号引流，私域信号变现，帮助交易者解决市场转化问题。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-modular modular-four"},[_c('div',{staticClass:"content flex"},[_c('div',{staticClass:"modular-four__info"},[_c('div',{staticClass:"zero-modular__tit"},[_vm._v(" 优势四:"),_c('p',[_vm._v("\"AI选手\"")]),_vm._v("一键筛选信号 ")]),_c('div',{staticClass:"zero-modular__desc"},[_vm._v(" 根据跟单者风险/收益偏好，一键筛选目标信号，"),_c('br'),_vm._v(" 轻松复制全球顶级交易策略。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-modular modular-five"},[_c('div',{staticClass:"content flex"},[_c('div',{staticClass:"modular-five__info"},[_c('div',{staticClass:"zero-modular__tit"},[_vm._v(" 优势五:去伪存真的"),_c('p',[_vm._v("“交易社区”")])]),_c('div',{staticClass:"zero-modular__desc"},[_vm._v(" 集成化交易研究交流社区 ：EBC研究院、"),_c('br'),_vm._v(" 订单流学习基地、对冲基金研究策略…"),_c('br'),_vm._v(" 助您在交易之路上保持进步，学习成长。"),_c('br'),_c('br'),_c('div',{staticClass:"btns"},[_c('a',{attrs:{"href":"https://trading.ebfin.com","target":"_blank"}},[_vm._v(" 登录社区 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-modular modular-six"},[_c('div',{staticClass:"content flex"},[_c('div',{staticClass:"modular-six__info"},[_c('div',{staticClass:"zero-modular__tit"},[_c('p',[_vm._v("数百万")]),_vm._v("投资者的智慧结晶，触手可及！"),_c('br')]),_c('div',{staticClass:"zero-modular__desc"},[_vm._v(" 让您的投资之路离专业更近，让您的交易信心更笃定从容！ ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zero-footer"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"zero-footer__info flex flex-ajcenter"},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/image/img/icon-xx.svg")}}),_c('div',{staticClass:"info-line flex-1"}),_c('div',{staticClass:"info-desc"},[_vm._v(" 每个认真交易的人都值得被认真对待 ")]),_c('div',{staticClass:"info-line flex-1"}),_c('img',{staticClass:"img",attrs:{"src":require("../assets/image/img/icon-xx.svg")}})]),_c('div',{staticClass:"zero-footer__logo flex flex-ajcenter"},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/image/img/icon-6.svg")}})])])])
}]

export { render, staticRenderFns }